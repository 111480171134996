import { Button } from '@aiya/ui'
import React, { CSSProperties } from 'react'
import './Footer.css'
import { Link } from './Link'

type Props = {
  style?: CSSProperties
}

export const Footer = ({ style = {} }: Props) => {
  return (
    <div className="footer" style={{ ...style }}>
      <div className="footer--container">
        <div className="footer--left">
          <div>เปิดประสบการณ์การใช้แชทบอทที่เหนือกว่า</div>
          <div>จากผู้พัฒนานวัตกรรม ประสบการณ์มากกว่า 15 ปี</div>
          <div>ผลงานเหรียญทองจากประเทศเกาหลีใต้ปี 2017</div>
        </div>
        <div className="footer--center">
          <h4>Link</h4>
          <div>
            <Link url="https://fb.com/aiyaclub">เฟสบุ๊ค</Link>
          </div>
          <div>
            <Link url="/terms-and-conditions">เงื่อนไขการใช้บริการ</Link>
          </div>
          <div>
            <Link url="/privacy">นโยบายความเป็นส่วนตัว</Link>
          </div>
          <div>
            <Link url="https://aiya.ai/lineoa">เปิดบัญชี LINE Official Account</Link>
          </div>
        </div>
        <div className="footer--right">
          <h4>Address</h4>
          <div>188 อาคารสปริงทาวเวอร์ (วีเวิร์ค ชั้น11)​</div>
          <div>ถนนพญาไท แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพ 10400</div>
          <div>E-Mail: hello@aiya.ai</div>
          <div>Phone: (+66) 02 821 5699</div>
        </div>
      </div>
    </div>
  )
}
