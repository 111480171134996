import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import './BotTemplate.css'

type Item = {
  title_th: string
  title_en: string
  icon_url: string
  picture_url: string
  description: string
  features: string[]
}

type Props = {
  items: Item[]
}

export const BotTemplate = ({ items }: Props) => {
  return (
    <div className="bot-template">
      {items?.map((item, i) => (
        <div
          key={i}
          className={[
            'bot-template--container',
            i % 2 ? 'bot-template--container--odd' : 'even',
          ].join(' ')}
        >
          <div className="left">
            <img src={item?.picture_url} />
          </div>
          <div className="right">
            <div className="title">{item?.title_en}</div>
            <div className="description">{item?.description}</div>
            <ul className="feature-list">
              {item?.features?.map((feature, key) => (
                <li key={key}>{feature}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  )
}
