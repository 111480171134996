import React from 'react'

import LOGO_ACRM from '../assets/Logo/logo-acrm-fill.png'
import HIGHLIGHT from '../assets/screenshot/highlight.png'

import './WhyUs.css'

export const WhyUs = () => {
  return (
    <div className="why-us">
      <div className="logo">
        <img src={LOGO_ACRM} />
      </div>
      <div className="topic">AIYA CUSTOMER RELATIONSHIP MANAGEMENT</div>
      <div className="description">
        <div>ลดต้นทุน คืนกำไร ให้ธุรกิจ</div>
        <div>พัฒนาระบบ CRM ให้ถูกใจลูกค้า</div>
        <div>เตรียมตัวให้พร้อมดูแลลูกค้า</div>
      </div>
      <div className="highlight">
        <img src={HIGHLIGHT} width="100%" />
      </div>
    </div>
  )
}

export default WhyUs
