export const getEnv = () => {
  const host = window.location.host
  const [subdomain] = host.split('.')
  const [env] = subdomain.split('-')
  let result = env
  if (window.location.hostname === 'localhost') {
    result = 'localhost'
  }

  return result
}
