const cryptoRandomString = require('crypto-random-string')

const BASE58_CHARSETS = '123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ'

export const randomId = (opts?: {
  length?: number
  charset?: string
  capitalization?: string
  type?: string
}) => {
  const { length = 8, charset, capitalization, type } = opts || {}
  if (type === 'base58') {
    return cryptoRandomString({ length, charset, capitalization, characters: BASE58_CHARSETS })
  }
  return cryptoRandomString({ length, charset, capitalization, type })
}
