import React from 'react'
import PT from 'prop-types'

export const InputField = ({ children, title, compact, isRequired, style }) => {
  if (compact) {
    return (
      <div style={{ position: 'relative', marginBottom: 16, ...style }}>
        <div>{children}</div>
        <label
          style={{
            position: 'absolute',
            top: 0,
            left: 8,
            transform: 'translateY(-50%)',
            backgroundColor: 'white',
            padding: '0 4px',
            fontSize: '0.85em',
            borderRadius: 8,
            color: 'rgba(183, 183, 183, 1)',
          }}
        >
          <span style={{ fontWeight: 500, color: '#888' }}>{title}</span>
          {isRequired && <sup style={{ color: 'red' }}>*</sup>}
        </label>
      </div>
    )
  } else {
    return (
      <div style={{ marginBottom: 16, ...style }}>
        <div style={{ marginBottom: 4 }}>
          <label style={{ fontWeight: 500, color: '#888' }}>{title}</label>
          {isRequired && <sup style={{ color: 'red' }}>*</sup>}
        </div>
        <div>{children}</div>
      </div>
    )
  }
}

InputField.propTypes = {
  title: PT.string.isRequired,
  compact: PT.bool,
  isRequired: PT.bool,
  style: PT.objectOf(PT.any),
  children: PT.node,
}

export default InputField
