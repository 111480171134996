import React from 'react'
import './Header.css'

import Logo from '../Logo/logo-acrm.png'

import { Button } from '@aiya/ui'
import { LoginOutlined } from '@ant-design/icons'

type Props = {
  logoUrl?: string
  onLoginClick?: () => void
  onButtonClick?: () => void
}

export const Header = (props: Props) => (
  <div className="ai-page--header">
    <div className="ai-page--header--container">
      <img
        className="logo"
        src={props.logoUrl}
        onClick={() => {
          window.location.href = '/'
        }}
      />
      <div>
        <div className="button-login" onClick={() => props.onLoginClick()}>
          เข้าสู่ระบบ <span> </span> <LoginOutlined />
        </div>
        <Button
          type="primary"
          ghost
          style={{ height: 35, lineHeight: '35px' }}
          onClick={() => props.onButtonClick()}
        >
          เปิดใช้งานฟรี
        </Button>
      </div>
    </div>
    {/* <button className="cta-contact">get in touch</button> */}
  </div>
)
