import React from 'react'
import PT from 'prop-types'
import { Input, Collapse, Switch } from 'antd'

import InputField from '../../components/InputField'
import JSONInput from '../../../JSONInput'

const ProductFlexEditor = ({ messageObj, onChange, loading }) => {
  const [useFixCard, setFixCard] = React.useState((messageObj.payload || {}).fixCard)
  const [input, setInput] = React.useState({
    message: messageObj.message,
    request: messageObj.request,
    mapping: messageObj.mapping,
    element: messageObj.element,
    child_mapping: messageObj.child_mapping,
    child_element: messageObj.child_element,
    payload: messageObj.payload,
  })

  React.useEffect(() => {
    setInput({
      message: messageObj.message,
      request: messageObj.request,
      mapping: messageObj.mapping,
      element: messageObj.element,
      child_mapping: messageObj.child_mapping,
      child_element: messageObj.child_element,
      payload: messageObj.payload,
    })
    setFixCard((messageObj.payload || {}).fixCard)
  }, [messageObj])

  const inputChange = (item) => {
    setInput({ ...input, ...item })
  }

  const handleFixCardToggle = (enabled) => {
    let payload = {}
    if (enabled) {
      payload = {
        fixCard: {
          type: 'bubble',
          body: {
            type: 'box',
            layout: 'vertical',
            spacing: 'sm',
            contents: [
              {
                type: 'button',
                action: {
                  type: 'message',
                  label: 'See more',
                  text: 'See more',
                },
                flex: 1,
                gravity: 'center',
              },
            ],
          },
        },
      }
    }
    setFixCard(enabled)
    inputChange({ payload })
    updateObj({ payload })
  }

  const updateObj = (override = {}) => {
    onChange({ ...input, ...override })
  }

  return (
    <>
      <Collapse accordion bordered={false}>
        <Collapse.Panel header="Product Search" key="product-search">
          <InputField title="Title">
            <Input
              value={((input.request || {}).variables || {}).title}
              onChange={(e) =>
                inputChange({
                  request: {
                    ...input.request,
                    variables: {
                      ...((input.request || {}).variables || {}),
                      title: e.target.value,
                    },
                  },
                })
              }
              onPressEnter={() => updateObj()}
              onBlur={() => updateObj()}
              disabled={loading}
            />
          </InputField>
          <InputField title="Type">
            <Input
              value={((input.request || {}).variables || {}).type}
              onChange={(e) =>
                inputChange({
                  request: {
                    ...input.request,
                    variables: {
                      ...((input.request || {}).variables || {}),
                      type: e.target.value,
                    },
                  },
                })
              }
              onPressEnter={() => updateObj()}
              onBlur={() => updateObj()}
              disabled={loading}
            />
          </InputField>
          <InputField title="Group">
            <Input
              value={((input.request || {}).variables || {}).group}
              onChange={(e) =>
                inputChange({
                  request: {
                    ...input.request,
                    variables: {
                      ...((input.request || {}).variables || {}),
                      group: e.target.value,
                    },
                  },
                })
              }
              onPressEnter={() => updateObj()}
              onBlur={() => updateObj()}
              disabled={loading}
            />
          </InputField>
        </Collapse.Panel>
        <Collapse.Panel header="Mapping & Element" key="mapping_element">
          <InputField title="Mapping">
            <Input
              value={input.mapping}
              onChange={(e) => inputChange({ mapping: e.target.value })}
              disabled={loading}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
          <InputField title="Element">
            <Input
              value={input.element}
              onChange={(e) => inputChange({ element: e.target.value })}
              disabled={loading}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
          <InputField title="Child Mapping">
            <Input
              value={input.child_mapping}
              onChange={(e) => inputChange({ child_mapping: e.target.value })}
              disabled={loading}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
          <InputField title="Child Element">
            <Input
              value={input.child_element}
              onChange={(e) => inputChange({ child_element: e.target.value })}
              disabled={loading}
              onBlur={() => updateObj()}
              onPressEnter={() => updateObj()}
            />
          </InputField>
        </Collapse.Panel>
        <Collapse.Panel header="Fix Card" key="fix_card">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
            <span style={{ marginRight: 8 }}>Enabled:</span>
            <Switch checked={useFixCard} onChange={(val) => handleFixCardToggle(val)} />
          </div>
          {useFixCard && (
            <InputField title="Fix Card">
              <JSONInput
                data={(input.payload || {}).fixCard || {}}
                onChange={(val) => updateObj({ payload: { fixCard: val } })}
                loading={loading}
              />
            </InputField>
          )}
        </Collapse.Panel>
      </Collapse>
      <div style={{ padding: '8px 16px' }}>
        <InputField title="JSON">
          <JSONInput
            data={input.message}
            onChange={(val) => updateObj({ message: val })}
            loading={loading}
          />
        </InputField>
      </div>
    </>
  )
}

ProductFlexEditor.propTypes = {
  message: PT.object,
  onMessageChange: PT.func,
  loading: PT.bool,
}

ProductFlexEditor.defaultProps = {
  message: {},
  onMessageChange: () => {},
}

export default ProductFlexEditor
