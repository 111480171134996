import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import { Modal, Tooltip } from 'antd'

import IconButton from '../../components/IconButton'
import IconMenu from '../../components/IconMenu'

import { defaultLineData } from '../../constants/defaultData'
import { defaultLineMsg } from '../../constants/defaultMsg'
import { LINE_MSG_TYPE } from '../../constants/msgType'

import FlexEditor from './FlexEditor'
import ImageEditor from './ImageEditor'
import ImagemapEditor from './ImagemapEditor'
import TextEditor from './TextEditor'
import DynamicFlexEditor from './DynamicFlexEditor'
import GoogleSheetFlexEditor from './GoogleSheetFlexEditor'
import ProductFlexEditor from './ProductFlexEditor'
import PlaceFlexEditor from './PlaceFlexEditor'
import BookingFlexEditor from './BookingFlexEditor'
import {
  MessageOutlined,
  PictureOutlined,
  AppstoreAddOutlined,
  ControlOutlined,
  ApiOutlined,
  GoogleOutlined,
  MenuOutlined,
  CloseOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'
import { MessageWrapper } from '../../types'
import { TOOLBAR_LINE_DEFAULT } from '../../constants/toolbar'

type Props = {
  index: any
  botId: string
  loading: boolean
  messageObj: any
  toolbar?: string[]
  onChange: (bubble: any) => void
  onDelete: (bubble: any) => void
}

export const LineMessageEditor = ({
  index,
  botId,
  loading,
  toolbar = TOOLBAR_LINE_DEFAULT,
  messageObj,
  onChange,
  onDelete,
}: Props) => {
  const { message, request, data } = messageObj
  const [toolbarButtons, setToolbarButtons] = useState([])

  const classifyMsg = (msg) => {
    if (!msg) {
      return
    }
    if (msg.type === 'text' && msg.text) {
      return LINE_MSG_TYPE.TEXT
    }
    if (msg.type === 'image') {
      return LINE_MSG_TYPE.IMAGE
    }
    if (msg.type === 'imagemap') {
      return LINE_MSG_TYPE.IMAGEMAP
    }
    if (msg.type === 'flex' && msg.altText && msg.contents) {
      if (request) {
        if ((data || {}).apiType === 'googleSheet') {
          return LINE_MSG_TYPE.GSHEET_FLEX
        } else if ((data || {}).apiType === 'product') {
          return LINE_MSG_TYPE.PRODUCT_FLEX
        } else if ((data || {}).apiType === 'place') {
          return LINE_MSG_TYPE.PLACE_FLEX
        } else if ((data || {}).apiType === 'booking') {
          return LINE_MSG_TYPE.BOOKING_FLEX
        } else {
          return LINE_MSG_TYPE.DYNAMIC_FLEX
        }
      } else {
        return LINE_MSG_TYPE.FLEX
      }
    }
    return LINE_MSG_TYPE.FLEX
  }

  const [msgType, setMsgType] = React.useState(classifyMsg(message))

  const renderByType = [
    {
      type: LINE_MSG_TYPE.TEXT,
      title: 'Text Message',
      icon: <MessageOutlined />,
      editor: (
        <TextEditor
          message={message}
          onMessageChange={(msg) => handleMessageObjChange({ message: msg })}
          loading={loading}
        />
      ),
    },
    {
      type: LINE_MSG_TYPE.IMAGE,
      title: 'Image Message',
      icon: <PictureOutlined />,
      editor: (
        <ImageEditor
          message={message}
          onMessageChange={(msg) => handleMessageObjChange({ message: msg })}
          botId={botId}
          loading={loading}
        />
      ),
    },
    {
      type: LINE_MSG_TYPE.IMAGEMAP,
      title: 'Imagemap Message',
      icon: <AppstoreAddOutlined />,
      editor: (
        <ImagemapEditor
          message={message}
          data={data}
          onMessageChange={(msg, dataObj) =>
            handleMessageObjChange({ message: msg, data: dataObj })
          }
          botId={botId}
          loading={loading}
        />
      ),
    },
    {
      type: LINE_MSG_TYPE.FLEX,
      title: 'Flex Message',
      icon: <ControlOutlined />,
      editor: (
        <FlexEditor
          message={message}
          onMessageChange={(msg) => handleMessageObjChange({ message: msg })}
          loading={loading}
        />
      ),
    },
    {
      type: LINE_MSG_TYPE.DYNAMIC_FLEX,
      title: 'Dynamic Flex',
      icon: <ApiOutlined />,
      editor: (
        <DynamicFlexEditor
          messageObj={messageObj}
          onChange={(obj) => handleMessageObjChange(obj)}
          loading={loading}
        />
      ),
    },
    {
      type: LINE_MSG_TYPE.GSHEET_FLEX,
      title: 'Google Sheet Flex',
      icon: <GoogleOutlined />,
      editor: (
        <GoogleSheetFlexEditor
          messageObj={messageObj}
          onChange={(obj) => handleMessageObjChange(obj)}
          loading={loading}
        />
      ),
    },
    {
      type: LINE_MSG_TYPE.PRODUCT_FLEX,
      title: 'Product',
      icon: <ShoppingCartOutlined />,
      editor: (
        <ProductFlexEditor
          messageObj={messageObj}
          onChange={(obj) => handleMessageObjChange(obj)}
          loading={loading}
        />
      ),
    },
    // {
    //   type: lineMsgType.PLACE_FLEX,
    //   title: 'Place Flex',
    //   icon: <Icon type="environment" />,
    //   editor: (
    //     <PlaceFlexEditor
    //       messageObj={messageObj}
    //       onChange={obj => handleMessageObjChange(obj)}
    //       loading={loading}
    //     />
    //   ),
    // },
    // {
    //   type: lineMsgType.BOOKING_FLEX,
    //   title: 'Booking Flex',
    //   icon: <Icon type="book" />,
    //   editor: (
    //     <BookingFlexEditor
    //       messageObj={messageObj}
    //       onChange={obj => handleMessageObjChange(obj)}
    //       loading={loading}
    //     />
    //   ),
    // },
  ]

  const handleMsgTypeChange = (type) => {
    if (loading) {
      return
    }

    Modal.confirm({
      title: 'ยืนยันการแก้ไข',
      content: (
        <div>
          <div>การแก้ไขข้อความจะทำให้ข้อความในกล่องนี้หายไป</div>
          <div>เพื่อความปลอดภัยกรุณาสำรองข้อความเก่าไว้ก่อน</div>
        </div>
      ),
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
      onOk: () => {
        let newMessageObj = {
          name: messageObj.name,
          type: 'box',
          message: defaultLineMsg[type],
          data: defaultLineData[type],
        } as MessageWrapper

        const isApiType = [
          LINE_MSG_TYPE.DYNAMIC_FLEX,
          LINE_MSG_TYPE.GSHEET_FLEX,
          LINE_MSG_TYPE.PRODUCT_FLEX,
          LINE_MSG_TYPE.PLACE_FLEX,
          LINE_MSG_TYPE.BOOKING_FLEX,
        ].includes(type)

        if (isApiType) {
          newMessageObj = {
            ...newMessageObj,
            type: 'API',
            request: {
              method: 'GET',
              uri: '',
              headers: {},
              variables: {},
            },
            mapping: 'message.contents.contents',
            element: '',
            child_mapping: '',
            child_element: '',
            payload: {},
          }
          if (type === LINE_MSG_TYPE.GSHEET_FLEX) {
            newMessageObj.request.uri = 'aiya://googlesheet'
            newMessageObj.request.variables = {
              columns: false,
              sheet: 1,
              q: '{{last_message}}',
              id: '',
            }
            newMessageObj.element = 'rows'
          } else if (type === LINE_MSG_TYPE.PRODUCT_FLEX) {
            newMessageObj.request.uri = 'https://dev-openapi.aiya.ai/bot/product-plugin/search'
            newMessageObj.request.variables = {
              bot_id: '{{bot.id}}',
              page: 1,
              size: 9,
              sort: 'weight',
              title: '{{last_message}}',
              type: '',
              group: '',
            }
            newMessageObj.element = 'data'
          } else if (type === LINE_MSG_TYPE.PLACE_FLEX) {
            newMessageObj.request.uri = 'aiya://place'
            newMessageObj.request.variables = {
              lat: '{{place.lat}}',
              lng: '{{place.lng}}',
              type: '{{slot.context}}',
            }
            newMessageObj.element = 'data'
          } else if (type === LINE_MSG_TYPE.BOOKING_FLEX) {
            newMessageObj.request.uri = 'aiya://my/booking'
            newMessageObj.request.variables = {
              size: 10,
              friend_id: '{{user.id}}',
            }
            newMessageObj.element = 'data'
          }
        }

        setMsgType(type)
        onChange(newMessageObj)
      },
      onCancel: () => {},
    })
  }

  const handleMessageObjChange = (item) => {
    const tmp = {
      ...messageObj,
      ...item,
    }
    onChange(tmp)
  }

  useEffect(() => {
    if (toolbar) {
      let buttons = renderByType.filter((component) => {
        return toolbar.includes(component.type)
      })
      setToolbarButtons(buttons)
    }
  }, [toolbar])

  return (
    <div
      style={{
        width: '360px',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: 4,
        marginBottom: 8,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 32,
          borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
          padding: '0 4px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <IconButton style={{ marginRight: 8 }}>
            <MenuOutlined />
          </IconButton>

          {toolbarButtons.map((obj) => (
            <Tooltip key={obj.type} title={obj.title}>
              <div>
                <IconMenu
                  key={obj.type}
                  onClick={() => handleMsgTypeChange(obj.type)}
                  active={msgType === obj.type}
                  style={{ marginRight: 4 }}
                >
                  {obj.icon}
                </IconMenu>
              </div>
            </Tooltip>
          ))}
        </div>
        <IconButton hoverColor="red" onClick={onDelete}>
          <CloseOutlined />
        </IconButton>
      </div>

      {(renderByType.find((obj) => obj.type === msgType) || {}).editor || <div />}

      <div
        style={{
          fontSize: '0.85em',
          borderTop: '1px solid rgba(0, 0, 0, 0.15)',
          padding: '0 8px',
        }}
      >
        <span style={{ fontWeight: 600, color: 'rgba(28, 112, 176, 1)' }}>{index + 1}</span>
      </div>
    </div>
  )
}

LineMessageEditor.propTypes = {
  botId: PT.string,
  index: PT.number,
  message: PT.object,
  data: PT.object,
  request: PT.object,
  loading: PT.bool,
  onChange: PT.func,
  onDelete: PT.func,
  toolbar: PT.array,
}

LineMessageEditor.defaultProps = {
  onChange: () => {},
  onDelete: () => {},
}

export default LineMessageEditor
